import styled from '@emotion/styled';
import CodeCopyBox from './CodeCopyBox';

const SCodeContainer = styled.div`
  display: flex;
  flex-direction: column;
  text-align: start;
  cursor: pointer;
  > div {
    cursor: pointer;
  }
  code {
    font-size: 12px !important;
    line-height: 16px;
  }
`;


export const CodeContainer = (props) => {
  return (
    <SCodeContainer
      className={'SCodeContainer'}
    >
      <CodeCopyBox
        code={props.code}
      />
    </SCodeContainer>
  );
};
