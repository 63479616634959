import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";
import Spline from '@splinetool/react-spline';

import Section from "../components/Section/index.jsx";
import Typography from "../components/Typography/index.jsx";

import ReactPlayer from 'react-player'


const SSection = styled(Section)`
  padding-bottom: 0;
  padding-top: 0;

  @media only screen and (min-width: 600px){
    .Content {
      display: flex;
      flex-direction: row-reverse;
      max-width: 100%;
      text-align: left;
      margin: unset;
      float: right;
      div {
        text-align: left;
      }
    }
  }
  @media only screen and (min-width: 1098px){
    .Content {
      max-width: calc( 100% - ( ( 100% - 1098px ) / 2 ) );
    }
  }
`;


const VideoBackgroundContainer = styled.div`
  position: relative;
  width: calc(100vw);
  height: 80vw;
  overflow: hidden;
  margin-top: 0;
  border-radius: 0;
  @media only screen and (min-width: 500px){
    height: calc(100vw - 32px);
  }
  @media only screen and (min-width: 600px){
    margin-top: 0;
    border-radius: 0;
    margin-right: -16px;
    min-height: 650px;
    height: 75vh;
  }
  @media only screen and (min-width: 732px){
    /* height: 700px; */
    width: calc(100vw - 64px);
  }
  div:first-of-type {
    height: 100% !important;
    width: 100% !important;
    display: flex;
    video {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  .spline {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
    @media only screen and (max-width: 600px){
      width: 200% !important;
      height: 200% !important;
      scale: 0.5;
      margin-top: -40%;
      margin-left: -50%;
    }
  }
`;

const TextContainer = styled.div`
  overflow-wrap: anywhere;
  @media only screen and (min-width: 600px){
    padding-right: 32px;
  }
`;

const Table = styled.div`
  margin-top: 32px;
  table {
      border-collapse: separate;
  }
  td {
      align-content: center;
  }
`

function Relayers(props) {

  function onLoadSpline(spline) {
    const obj = spline.findObjectByName('Cube');
  }

  return (
    <SSection
      id={"Servers"}
      gradient
      center
    >

      <VideoBackgroundContainer
        className="VideoBackgroundContainer"
      >
        <ReactPlayer
          className="VideoBackground"
          url='videos/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4'
          playing
          loop
          muted
        />
        <Spline
          className="spline"
          //   scene="spline/scene.splinecode"
          scene="https://prod.spline.design/8KpG2dYYMmyY4CpS/scene.splinecode"
          onLoad={onLoadSpline}
        />
      </VideoBackgroundContainer>


      <TextContainer>
        <Typography type="h2">
          RELAYERS
        </Typography>
        <Table>
          <table>
            <tbody>
              <tr>
                <th>alias</th>
                <th>peerId</th>
              </tr>
              <tr>
                <td>hoprd-core-dufour-1</td>
                <td>12D3KooWQeRaz9YWr4ehfTnkwUVy79xZoymr25guJqHjogNM2BqJ</td>
              </tr>
              <tr>
                <td>hoprd-core-dufour-2</td>
                <td>12D3KooWScC7bj5YdjDLDX3ibxQjkJLeHPRGk4fdNr7jWn9ugYko</td>
              </tr>
              <tr>
                <td>hoprd-core-dufour-3</td>
                <td>12D3KooWNwGMS67P2CbSLehU7U1DENzxQQEotMNhHNo6cY7AybSc</td>
              </tr>
              <tr>
                <td>hoprd-core-dufour-4</td>
                <td>12D3KooWRvkXaiogDRCyWpdDaDiRm5ixTjDfd4f3RpS1behRdBS5</td>
              </tr>
              <tr>
                <td>hoprd-core-dufour-5</td>
                <td>12D3KooWRbkemN63B4kirDtcEUH6pegkNHV5TxdJRX4LYMgyLysF</td>
              </tr>

            </tbody>
          </table>
        </Table>
      </TextContainer>
    </SSection>
  );
}

export default Relayers;
