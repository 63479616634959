import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import Button from '../components/Button/index.jsx'

const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

const Image = styled.img`
  height: auto;
  max-width: 100%;
  background-color: #FFF;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 2px 34px -7px rgb(0 0 0 / 50%);
  &.mobileOnly {
    margin-bottom: 16px;
    @media (min-width: 700px) {
      display: none;
    }
  }
`

function Section7() {
    return (
        <SSection
            id={'Section10'}
            center
            yellow
        >
            <Typography type="h2">
                GnosisVPN.js
            </Typography>

            <Typography center>
                As a first step towards Gnosis VPN, you can now use GnosisVPN.js (previously: unlinked HTTP - uHTTP) which routes HTTP requests and responses through the HOPR network - right from your browser without installing any additional apps or services.
            </Typography>
            <br />
            <Image src="/gnosisvpn/Architecture_Graphic_WebApp_uhttp-v4.svg" />
            <br />
            <Typography center>
                Exit nodes should be operated on premises by service providers. Please contact us if you would like to run a HOPR Session exit node to offer anonymous routing for your users.
            </Typography>

            {/* <Button
                hopr
                href='mailto:uHTTP@hoprnet.org'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
               Contact us
            </Button> */}


        </SSection>
    );
}

export default Section7;
