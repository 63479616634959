export function doWeHaveHardwareAcc() {
  try {
    const canvas = document.createElement('canvas');
    const gl = canvas.getContext('webgl') || canvas.getContext('experimental-webgl');
    if (gl) {
      const debugInfo = gl.getExtension('WEBGL_debug_renderer_info');
      const renderer = gl.getParameter(debugInfo.UNMASKED_RENDERER_WEBGL);

      try {
        if (renderer.includes('llvmpipe') || renderer.includes('SwiftShader')) {
          return false;
        }

        if (renderer.length < 12) {
          return false;
        }
      } catch (e) {
        return false;
      }

      return true;
    }
  } catch (e) { }

  return false;
};