import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

import Section from "../components/Section/index.jsx";
import Typography from "../components/Typography/index.jsx";

import ReactPlayer from 'react-player'


const SSection = styled(Section)`
  padding-bottom: 40px;
  padding-top: 40px;
`;

const VideoBackgroundContainer = styled.div`
  position: relative;
  padding-top: 56.25%; /* 720 / 1280 = 0.5625 */
  margin: 16px;

  .VideoInAction {
    position: absolute;
    top: 0;
    left: 0;
    width: 100% !important;
    height: 100% !important;
  }

  .loading-video{
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    text-align: center;
    align-content: center;
    color: #FFF;
    font-size: 30px;
  }
`;

const Subtext = styled(Typography)`
  max-width: 640px;
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
  .mb32 {
    margin-bottom: 32px
  }
  margin: auto;
`;

const TextContainer = styled.div`

`;

function Section1(props) {

  function onLoadSpline(spline) {
    const obj = spline.findObjectByName('Cube');
  }

  return (
    <SSection
      id={"Section1"}
      center
    >
      <TextContainer>
        <Typography type="h2">
          SEE GNOSIS VPN IN ACTION
        </Typography>

        <VideoBackgroundContainer
          className="VideoInActionContainer"
        >
          <ReactPlayer
            className="VideoInAction VideoPlaceholder"
            url='videos/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4'
            playing
            loop
            muted
            width={"100%"}
            height={"100%"}
          />
          <span
            className="loading-video"
          >
            Loading...
          </span>

          <ReactPlayer
            className="VideoInAction VideoReal"
            url='./videos/Gnosis_VPN_Preview_Website_enc24.mp4'
            title="Gnosis VPN Preview"
            playing
            light={'./videos/Gnosis_VPN_Preview_Website_2k-preview.webp'}
            muted
            controls
            width={"100%"}
            height={"100%"}
          />
        </VideoBackgroundContainer>

        <Subtext center mb32>
          Even at the proof of concept stage, Gnosis VPN can achieve high browsing speeds over the HOPR mixnet, including streaming at 720p. All footage was captured using the Gnosis VPN PoC running over the HOPR mixnet. Exit nodes hosted by Gnosis. Footage captured under ideal conditions. Actual performance can vary.
        </Subtext>
      </TextContainer>
    </SSection>
  );
}

export default Section1;
