import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import HOPRUserVPNExplorer from "../assets/gnosisvpn/HOPR_User-VPN_Explorer.json";

const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

const Subtext = styled(Typography)`
  max-width: 640px;
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
  .mb32 {
    margin-bottom: 32px
  }
`;

function Section2B() {
    return (
        <SSection
            id={'Section2B'}
            center
            fullHeight
        >
            <Typography type="h2">
                I ALREADY HAVE A VPN!
            </Typography>
            <Subtext center marginTop={16}>
            Current VPNs are better than nothing (probably), but they come with unacceptable trust assumptions.
            </Subtext>

            <Brick
                className='mbt40'
                text="Centralized VPNs shield your metadata from websites, but not their server operators. These VPNs just replace trust in one server with trust in a different one."
                animationId='HOPRUserVPNExplorer'
                animationData={HOPRUserVPNExplorer}
            />
            <Brick
                className='mbt40'
                text={<>
                    As a web3 user on a centralized VPN, your anonymity set is the set of all users using:
                    <ul>
                        <li>The same RPC provider</li>
                        <li>Using the same VPN server</li>
                        <li>At the same time</li>
                    </ul>
                    This is almost certainly 1. So your identity can still be linked to activities on the web2.0 and web3.</>
                }
                image="/gnosisvpn/HOPR_Privacy_loves_company.svg"
                reverse
            />
        </SSection>
    );
}

export default Section2B;
