import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import HOPRUserHacker from "../assets/gnosisvpn/HOPR_User-Hacker.json";
import HOPR_Animation_Peer_to_Peer_to_Creep from "../assets/gnosisvpn/HOPR_Animation_Peer_to_Peer_to_Creep.json";



const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

function Section2() {
    return (
        <SSection
            id={'Section2'}
            center
        >
            <Typography type="h2">
                WHY GNOSIS VPN?
            </Typography>

            <Brick
                className='mbt40'
                text="Web2.0 infrastructure harvests metadata and links activities and identities across domains"
                animationId='HOPRUserHacker'
                animationData={HOPRUserHacker}
            />
            <Brick
                className='mbt40'
                text={`Lack of enforceable relationships in permissionless peer-to-peer networks means web3 infrastructure actually has worse privacy trust assumptions than the web2.0`}
                animationId='HOPR_Animation_Peer_to_Peer_to_Creep'
                animationData={HOPR_Animation_Peer_to_Peer_to_Creep}
                reverse
            />
        </SSection>
    );
}

export default Section2;
