import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import BrickVertical from '../components/BrickVertical/index.jsx'
import HOPRUserVPNExplorer from "../assets/gnosisvpn/HOPR_User-VPN_Explorer.json";

const SSection = styled(Section)`
  padding-bottom: 40px;
  padding-top: 40px;
`


const Table = styled.div`
    margin-top: 32px;
    table {
        border-collapse: separate;
        text-align: center;
    }
    td {
        align-content: center;
    }
    .mobile-show {
            display: none;
            p {
                margin: 0;
            }
    }
    th:nth-child(1),
    td:nth-child(1)
    {
        text-align: left;
    }
    th:nth-child(5),
    td:nth-child(5)
    {
        padding: 12px 0;
        max-width: 130px;
    }
    th:nth-child(6),
    td:nth-child(6)
    {
        text-align: right;
    }
    @media only screen and (max-width: 600px){
        font-size: 11px;
        th:nth-child(6),
        td:nth-child(6)
        {
            text-align: center;
        }
    }
    @media only screen and (max-width: 520px){
        .mobile-hide {
            display: none;
        }
        .mobile-show {
            display: table-cell;
        }
    }
    @media only screen and (max-width: 380px){
        th:nth-child(6),
        td:nth-child(6)
        {
            display: none;
        }
    }
    @media only screen and (max-width: 340px){
        th:nth-child(5),
        td:nth-child(5)
        {
            display: none;
        }
    }
`

const Subtext = styled(Typography)`
  max-width: 960px;
  text-align: center;
`

function Section4A() {
    return (
        <SSection
            id={'Section5'}
            center
            yellow
        >
            <Typography type="h2">
                NEW LEVELS OF DIGITAL PRIVACY
            </Typography>
            <Subtext>
                Gnosis VPN will provide unprecedented levels of anonymity online, and even the proof of concept version provides stronger privacy than most currently available VPNs.
            </Subtext>
            <Table>
                <table>
                    <tbody>
                        <tr>
                            <th className="mobile-hide"></th>
                            <th>Gnosis VPN</th>
                            <th>Gnosis VPN PoC</th>
                            <th>Regular VPNs</th>
                            <th>Decentralized VPNs</th>
                            <th>TOR</th>
                        </tr>
                        <tr>
                            <td className="mobile-show" colSpan={5}>
                                <strong>Unblockable</strong>
                                <p>Cannot be censored by states, ISPs or website providers</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <strong>Unblockable</strong>
                                <p>Cannot be censored by states, ISPs or website providers</p>
                            </td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                        </tr>
                        <tr>
                            <td className="mobile-show" colSpan={5}>
                                <strong>Surveillance-resistant</strong>
                                <p>Each packet is routed through different nodes to hide your IP and other metadata</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <strong>Surveillance-resistant</strong>
                                <p>Each packet is routed through different nodes to hide your IP and other metadata</p>
                            </td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                        </tr>
                        <tr>
                            <td className="mobile-show" colSpan={5}>
                                <strong>Web3 Incentives</strong>
                                <p>Unlike traditional models, revenue goes to users running relay and exit nodes</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <strong>Web3 Incentives</strong>
                                <p>Unlike traditional models, revenue goes to users running relay and exit nodes</p>
                            </td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                        </tr>
                        <tr>
                            <td className="mobile-show" colSpan={5}>
                                <strong>Protect metadata</strong>
                                <p>Obscure your IP and other metadata.</p>
                            </td>
                        </tr>
                        <tr>
                            <td className="mobile-hide">
                                <strong>Residential IPs</strong>
                                <p>Using the residential IP range circumvents most VPN blockers</p>
                            </td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                            <td><img src='images/table-tick.svg' /></td>
                            <td><img src='images/table-cross.svg' /></td>
                        </tr>
                    </tbody>
                </table >
            </Table >
        </SSection >
    );
}

export default Section4A;
