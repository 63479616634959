import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import Button from '../components/Button/index.jsx'
import Faq from "../components/Faq/index.jsx";


const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

function Section14() {
    return (
        <SSection
            id={'Section14'}
            center

        >
            <Typography type="h2">
                FAQ
            </Typography>
            <Faq/>
        </SSection>
    );
}

export default Section14;
