import React from 'react';
import styled from "@emotion/styled";
import Logo from './logo.svg'
import { Button as ButtonMui } from '@mui/material'
import { useNavigate, useLocation } from "react-router";

const SNavBar = styled.div`
  height: 68px;
  position: fixed;
  top: 0;
  width: 100%;
  background: white;
  z-index: 10;
  .go-home{
    cursor: pointer;
  }
  @media screen and (max-width: 600px) {
    position: absolute;
  }
`

const Container = styled.div`
  height: 100%;
  max-width: 1096px;
  margin: auto;
  display: flex;
  align-items: center;
`


const LogoImage2 = styled.img`
  height: 40px;
  width: calc(100% - 32px);
  margin-left: 16px;
  max-width: 320px;
  object-fit: contain;
  object-position: left;
`

const BtnContainer = styled.div`
  display: none;
  @media screen and (min-width: 800px) {
    display: flex;
    width: 100%;
    gap: 24px;
    justify-content: right;
  }
  @media screen and (min-width: 970px) {
    display: flex;
    position: fixed;
    width: 100vw;
    justify-content: center;
    left: 0;
  }
`

const NavButton = styled(ButtonMui)`
  font-family: "Source Code Pro";
  font-size: 18px;
  color: #414141;
  :hover {
    color: #414141;
    background-color: rgba(103, 106, 109, 0.1);
  }
`

const NavBar = () => {
  const navigate = useNavigate();
  const location = useLocation();


  return (
    <SNavBar>
      <Container>
        <LogoImage2
          alt="Gnosis VPN Logo"
          src={'./gnosis-vpn-new-logo.svg'}
          onClick={() => { navigate('/') }}
          className='go-home'
        />
        {
          location.pathname === '/' &&
          <BtnContainer>
            <NavButton
              onClick={(event) => {
                event.preventDefault()
                const id = 'Section4';
                const yOffset = -60;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
              }}
            >
              FEATURES
            </NavButton>
            <NavButton
              onClick={(event) => {
                event.preventDefault()
                const id = 'Section6';
                const yOffset = -60;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
              }}
            >
              ARCHITECTURE
            </NavButton>
            <NavButton
              onClick={(event) => {
                event.preventDefault()
                const id = 'Section7';
                const yOffset = -60;
                const element = document.getElementById(id);
                const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                window.scrollTo({ top: y, behavior: 'smooth' });
              }}
            >
              DOWNLOAD
            </NavButton>
          </BtnContainer>
        }
      </Container>
    </SNavBar>
  );
};

export default NavBar;
