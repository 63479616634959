import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import BrickVertical from '../components/BrickVertical/index.jsx'
import HOPRUserVPNExplorer from "../assets/gnosisvpn/HOPR_User-VPN_Explorer.json";

const SSection = styled(Section)`
  padding-bottom: 40px;
  padding-top: 40px;
`


const FlexBox = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  gap: 16px;
  max-width: 100%;
  margin-top: 32px;
  > div {
    max-width: 320px;
  }
`


const Subtext = styled(Typography)`
  margin-top: 32px;
  max-width: 960px;
  text-align: center;
`


function Section4A() {
    return (
        <SSection
            id={'Section4'}
            center
            yellow
        >
            <Typography type="h2">
                FEATURES
            </Typography>
            <FlexBox>
                <BrickVertical
                    title="TRUSTLESS"
                    text={`Decentralized architecture protects your IP and other metadata`}
                    image="/images/TRUSTLESS-icon.svg"
                />
                <BrickVertical
                    title="GLOBAL"
                    text={`Data is relayed via a growing worldwide network of individual users`}
                    image="/images/GLOBAL-icon.svg"
                />
                <BrickVertical
                    title="UNCENSORABLE"
                    text={`Gnosis VPN’s unique architecture makes it immune to standard VPN blocking techniques`}
                    image="/images/UNCENSORABLE-icon.svg"
                />
            </FlexBox>
            <Subtext>
                (Features of full Gnosis VPN. The PoC has higher trust assumptions but still offers more privacy than most centralized VPNs.)
            </Subtext>
        </SSection>
    );
}

export default Section4A;
