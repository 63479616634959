import React, { useState } from 'react';
import styled from '@emotion/styled';


const Content = styled.section`
  align-items: left;
  display: flex;
  flex-direction: column;
  gap: 1rem;
  margin-inline: auto;
  padding-block: 2rem;
  padding-inline: 1rem;
  width: min(100%, 848px);
  font-family: Source Code Pro;
  font-weight: 400;
`;


const QuestionAndIcon = styled.section`
  border-bottom: 1px solid rgb(0, 0, 80, 0.2);
  cursor: pointer;
  display: flex;
  justify-content: space-between;
  padding-bottom: 0.5rem;
  padding-inline: 0.5rem;
`;

const Icon = styled.img`
  height: 24px;
  transition: 0.4s;
  width: 24px;
  transform: ${(props) => (props.flipIcon ? 'rotate(180deg)' : 'rotate(0)')};
`;


const Paragraph = styled.p`
  line-height: 1.5;
  margin: 0;
  text-align: left;
`;


const Question = styled(Paragraph)`
  flex: 1;
`;

const Answer = styled(Paragraph)`
  max-height: ${(props) => (props.showQuestion ? '400px' : 0)};
  overflow: hidden;
  padding-inline: 1rem;
 // ${(props) => (props.showQuestion ? 'transition: max-height 0.3s ease-in-out;' : '')};
`;


const ParagraphContainer = styled.section`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`;


const faq = {
  id: 'faq',
  title: 'FAQ',
  information: [
    {
      id: 0,
      title: 'Why do we need Gnosis VPN?',
      text: `We spend more and more time online, but it’s becoming less private and free. VPNs are a rapidly growing response to this issue—a market estimated to be worth $70B a year. However, current VPNs come with unacceptable trust assumptions and are easily blocked. We need a VPN that anyone can use to access the internet as it was meant to be.`,
    },
    {
      id: 1,
      title: 'Does Gnosis VPN really protect all my data and metadata?',
      text: 'GnosisVPN offers far better trust assumptions than current VPNs, even in the PoC. However, you still need to take care online. Like all other VPNs, Gnosis VPN exit nodes can see all plaintext HTTP requests and responses. Do not use them to route confidential information over unencrypted connections and make sure your browser always uses secure HTTPS and not unencrypted plain HTTP. While Gnosis VPN exit nodes allow anonymous access to the internet, you can still be deanonymized if you voluntarily expose information (e.g., plaintext credit card details).',
    },
    {
      id: 2,
      title: 'When will Gnosis VPN be available?',
      text: 'We’ve estimated a development period of two years to create a highly usable, globally scalable version of Gnosis VPN, while other versions for more technical users will be released intermittently. The proof of concept version is already available to try.',
    },
    {
      id: 3,
      title: 'How do I try the proof of concept?',
      text: <>You can try the proof of concept{" "}<a
      href='https://github.com/gnosis/gnosis_vpn-client/blob/main/ONBOARDING.md'
      target="_blank"
      rel="noopener noreferrer"
    >
      here
    </a>. You’ll need to run a HOPR node for it to work and be comfortable with using the command line interface (CLI). Certain stages require you to wait for access approval, so please be patient.</>,
    },
    {
      id: 4,
      title: 'Can I use Gnosis VPN PoC where I live?',
      text: 'While we believe unblockable encrypted internet should be available to all, sadly not everyone agrees. Users should check local laws before using the PoC (including operating a relay node). Users are solely responsible for how they use and interact with the PoC, and for complying with applicable laws. The PoC should not be used for illegal, malicious or unethical purposes.',
    },
    {
      id: 5,
      title: 'Why does the proof of concept have an allow list?',
      text: 'Although we’re pleased with the progress made developing the PoC, with packet transfer rates 60x higher than estimated at the start of development, the underlying HOPR network is still vulnerable to sudden spikes in traffic. As we scale the network, these restrictions will gradually be removed.',
    },
    {
      id: 6,
      title: 'I want to run a node! What should I do?',
      text: <>Exit nodes for Gnosis VPN are currently hosted on Gnosis infrastructure. We’ll announce when this is opened up to outside participation. In the meantime, you can help by running a HOPR node to relay data within the HOPR mixnet.{" "}
      <a
        href='https://hub.hoprnet.org/'
        target="_blank"
        rel="noopener noreferrer"
      >
          Get started here
      </a>.</>
    },

  ],
};



const Faq = () => {
  const [questionIndex, set_questionIndex] = useState(null);

  const handleQuestionClick = (index) => {
    if (questionIndex === index) {
      set_questionIndex(null);
    } else {
      set_questionIndex(index);
    }
  };

  return (
    <Content>
      {faq.information.map((item) => (
        <ParagraphContainer key={item.id}>
          <QuestionAndIcon onClick={() => handleQuestionClick(item.id)}>
            <Question>{item.title}</Question>
            <Icon
              src={'/images/faq_arrow.svg'}
              alt={'Faq arrow'}
              flipIcon={questionIndex === item.id}
            />
          </QuestionAndIcon>
          {item.text && (
            <Answer
              showQuestion={questionIndex === item.id}
            >
              {item.text}
            </Answer>
          )}
        </ParagraphContainer>
      ))}
    </Content>
  );
};
export default Faq;
