import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Brick from '../components/Brick/index.jsx'
import Button from '../components/Button/index.jsx'

const SSection = styled(Section)`
  padding-bottom: 80px;
  padding-top: 80px;
`

function Section9() {
    return (
        <SSection
            id={'Section13'}
            center
            yellow
        >
            <Typography type="h2">
                WANT MORE?
            </Typography>
            <Typography center>
                If you liked our HOPR Session demo, check out more apps and showcases that we built at HOPR to highlight web3 privacy issues:
            </Typography>
            <Button
                hopr
                href='https://mint.hoprnet.org/'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
                MINT A NON-PRIVATE NFT
            </Button>

            <br/>
            <Button
                hopr
                href='https://derp.hoprnet.org/'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
                CHECK YOUR WALLET ON DERP
            </Button>

            <br/>
            <Button
                hopr
                href='https://medium.com/hoprnet/proof-of-stake-validator-sniping-research-8670c4a88a1c'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
                DOX YOUR ETHEREUM VALIDATOR
            </Button>
        </SSection>
    );
}

export default Section9;
