import React, { useEffect, useState } from "react";
import styled from "@emotion/styled";
import { CodeContainer } from "../components/Code/CodeContainer.jsx";
import Section from "../components/Section/index.jsx";
import Typography from "../components/Typography/index.jsx";
import { doWeHaveHardwareAcc } from "../shared/doWeHaveHardwareAcc.js";
import { useLocation } from "react-router";


const SSection = styled(Section)`
  padding-bottom: 0px;
  padding-top: 0;

`;


const VideoBackgroundContainer = styled.div`
  position: relative;
  max-width: 30vw;
    max-height: 30vw;
    width: 100%;
    height: 100%;
  overflow: hidden;
  top: 0;
  right: 0;
  margin-top: 0;
  border-radius: 0;
  background-image: url(./placeholder/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4_snapshot_00.01.226.webp);
  background-position: right;
  background-size: cover;
  margin: 16px;
  border-radius: 16px;
  div:first-of-type {
    height: 100% !important;
    width: 100% !important;
    display: flex;
    video {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
  .spline {
    position: absolute;
    top: 0;
    left: 0;
    pointer-events: none;
  //  @media only screen and (max-width: 600px){
      width: 200% !important;
      height: 200% !important;
      scale: 0.5;
      margin-top: -40%;
      margin-left: -50%;
  //  }
  }
`;

const Subtext = styled(Typography)`
  font-size: 13px;
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
  .mb32 {
    margin-bottom: 32px
  }
`;

const TextContainer = styled.div`
  max-width: 650px;
  width: 100%;
  overflow-wrap: anywhere;

  #allowlist {
    margin-top: 60px;
  }

  ul{
    max-width: 236px;
    margin: auto;
    margin-top: 16px;
    padding-left: 48px;
    text-align: left;
  }
`;

const SplineReplacement = styled.img`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  object-fit: scale-down;
  object-position: center;
`


function Servers(props) {
  const location = useLocation();
  const [weHaveHardwareAcc, set_weHaveHardwareAcc] = useState(true);

  useEffect(() => {
    const tmp = doWeHaveHardwareAcc();
    set_weHaveHardwareAcc(tmp)
  }, []);

  useEffect(() => {
    const hash = location.hash;
    if(hash !== `#allowlist`) return;
    const id = 'allowlist';
    const yOffset = -80;
    const element = document.getElementById(id);
    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
    window.scrollTo({top: y, behavior: 'smooth'});
  }, [location]);



  return (
    <SSection
      id={"Servers"}
      gradient
      center
    >

      {/* <VideoBackgroundContainer
        className="VideoBackgroundContainer"
      >
        <ReactPlayer
          className="VideoBackground"
          url='videos/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4'
          playing
          loop
          muted
        />

{
          weHaveHardwareAcc ?
          <Spline
            className="spline"
            scene="https://prod.spline.design/8KpG2dYYMmyY4CpS/scene.splinecode"
          />
          :
          <SplineReplacement
            src="./gnosisvpn/gnosis-vpn-cube.png"
          />
        }
      </VideoBackgroundContainer> */}
        <br/>
        <Typography type="h2">
          SERVERS
        </Typography>

        <Subtext
          center
          mb32
          style={{
            maxWidth: "1098px"
          }}
        >
          Choose your exit server. To manage load and aid with testing and debugging, these exit nodes currently gather logs. These logs cannot reveal user requests, server responses, IPs or any other identifying information. Logs are deleted after thirty days.<br/><br/>

          Users should check local laws before using Gnosis VPN PoC (including operating a relay node). Users are solely responsible for how they use and interact with the PoC, and for complying with applicable laws. The PoC should not be used for illegal, malicious or unethical purposes.<br/><br/>
        </Subtext>
      <TextContainer>
        <Subtext center mb32>
          <strong>Exit Location Germany:</strong><br />
          Copy this into your <code>config.toml</code> or update parameters manually.<br />
          Make sure you override existing settings.<br />


          <CodeContainer
            code={`[connection]
destination = "12D3KooWMEXkxWMitwu9apsHmjgDZ7imVHgEsjXfcyZfrqYMYjW7"

[connection.path]
intermediates = [ "12D3KooWFUD4BSzjopNzEzhSi9chAkZXRKGtQJzU482rJnyd2ZnP" ]
`}
          />
          <br />
          Needs an open payment channel to this community relay node <code>12D3KooWFUD4BSzjopNzEzhSi9chAkZXRKGtQJzU482rJnyd2ZnP</code> (node address: <code>0xD88064F7023D5dA2Efa35eAD1602d5F5d86BB6BA</code>).<br /><br />



          <br />
          <strong>Exit Location USA:</strong><br />
          Copy this into your <code>config.toml</code> or update parameters manually.<br />
          Make sure you override existing settings.<br />


          <CodeContainer
            code={`[connection]
destination = "12D3KooWBRB3y81TmtqC34JSd61uS8BVeUqWxCSBijD5nLhL6HU5"

[connection.path]
intermediates = [ "12D3KooWQLTR4zdLyXToQGx3YKs9LJmeL4MKJ3KMp4rfVibhbqPQ" ]`}
          /><br />
          Needs an open payment channel to this community relay node <code>12D3KooWQLTR4zdLyXToQGx3YKs9LJmeL4MKJ3KMp4rfVibhbqPQ</code> (node address: <code>0x25865191AdDe377fd85E91566241178070F4797A</code>).

          <br /><br /><br />

          <br />
          <strong>Servers public key:</strong><br />
          The wireguard server public key needed to be added to the wireguard config.<br />
          <CodeContainer
            code={`[Peer]
PublicKey = lm0QdCOxg4BxQK1wHujjHsOtSUKrNFgfbGxyWUH+hRk=`}
          />
          <div id="allowlist">
            <strong>ALLOW LIST OF SITES</strong><br />
            <ul>
              <li>0xgraph.xyz</li>
              <li>1inch.io</li>
              <li>aave.com</li>
              <li>aboutcircles.com</li>
              <li>alchemy.com</li>
              <li>ankr.com</li>
              <li>balancer.fi</li>
              <li>blocknative.com</li>
              <li>blockscout.com</li>
              <li>cloudflare-eth.com</li>
              <li>cloudfront.com</li>
              <li>coingecko.com</li>
              <li>cow.fi</li>
              <li>debank.com</li>
              <li>duckduckgo.com</li>
              <li>dune.com</li>
              <li>ethereum.org</li>
              <li>etherscan.io</li>
              <li>example.com</li>
              <li>fontlibrary.org</li>
              <li>framer.com</li>
              <li>github.com</li>
              <li>github.io</li>
              <li>gnosis-safe.com</li>
              <li>gnosis.io</li>
              <li>gnosischain.com</li>
              <li>gnosispay.com</li>
              <li>gnosisscan.io</li>
              <li>gnosisvpn.com</li>
              <li>hoprnet.link</li>
              <li>hoprnet.org</li>
              <li>infura.io</li>
              <li>llama.fi</li>
              <li>merkle.io</li>
              <li>metamask.io</li>
              <li>metri.xyz</li>
              <li>monerium.app</li>
              <li>monerium.com</li>
              <li>mytokentracker.xyz</li>
              <li>rabby.io</li>
              <li>safe.global</li>
              <li>static.debank.com</li>
              <li>swaps.io</li>
              <li>thegraph.com</li>
              <li>trezor.io</li>
              <li>walletconnect.com</li>
              <li>walletconnect.org</li>
              <li>whatismyip.org</li>
            </ul>
          </div>
        </Subtext>
      </TextContainer>
    </SSection>
  );
}

export default Servers;
