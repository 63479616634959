import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Button from '../components/Button/index.jsx'


const SSection = styled(Section)`
  padding-bottom: 40px;
  padding-top: 40px;
  align-items: flex-start;
  /* background-image: url('/images/RPCh-BG.jpg'); */
  /* background-position: top;
  background-size: cover; */
`

function Section9() {
    return (
        <SSection
            id={'Section9'}
            center
            darkGradient
        >
            <Typography
                type="h6"
                className="mb32"
                style={{color: 'white'}}
            >
                NEED HELP?
            </Typography>
            <Button
                hopr
                white
                href='https://github.com/gnosis/gnosis_vpn-client/blob/main/README.md'
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
            >
                VISIT THE GNOSIS VPN DOCS
            </Button>
        </SSection>
    );
}

export default Section9;
