import React from "react";
import styled from "@emotion/styled";
import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import BrickDownload from '../components/BrickDownload/index.jsx'
import BrickDownloadWide from '../components/BrickDownload/wide.jsx'
import ReactPlayer from 'react-player'
import Button from '../components/Button/index.jsx'
import { useNavigate } from "react-router";

const SSection = styled(Section)`
  padding-bottom: 40px;
  padding-top: 0;
  overflow: hidden;
  scroll-margin-top: 60px;
  div.Content {
    max-width: unset;
  }
`

const YellowBackground = styled.div`
    padding-top: 40px;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    background: #FFF;
    width: 100vw;
`

const YellowBackgroundGradient = styled.div`
    height: 160px;
    width: 100vw;
    margin-left: -16px;
    margin-right: -16px;
    padding-left: 16px;
    padding-right: 16px;
    background: linear-gradient(180deg,#FFF 33%,hsla(0,0%,85%,0) 100%);
`

const WhiteBackground = styled.div`
    background: linear-gradient(180deg, hsla(0, 0%, 85%, 0) 0%, #FFF 30%);
    margin-top: -100px;
    height: 200px;
    width: 100vw;
`


const VideoBackgroundContainer = styled.div`
  position: relative;
  width: 100vw;
  //min-height: 400px;
  min-height: 400px;
  min-height: 450px;
  margin-top: -115px;
  z-index: -1;
  overflow: hidden;
  margin-left: -16px;
  margin-right: -16px;
  background-image: url(./placeholder/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4_snapshot_00.01.226.webp);
  background-position: right;
  background-size: cover;

/* @media (max-width: 709px) {
    min-height: 800px;
    min-height: 340px;
} */

  div:first-of-type {
    position: absolute;
    height: 100% !important;
    width: 100% !important;
    display: flex;
    video {
      display: block;
      object-fit: cover;
      object-position: center;
      width: 100%;
    }
  }
`;

const FlexBox = styled.div`
    display: flex;
    flex-wrap: wrap;
    width: 100%;
    justify-content: center;
    gap: 16px;
    margin-top: -500px;
    margin-top: -465px;

    @media (min-width: 360px) {
        margin-top: -480px;
    }

    @media (min-width: 900px) {
        margin-top: -455px;
    }
`

const Content = styled.div`
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;
`

const Subtext = styled(Typography)`
  max-width: 1098px;
  margin: auto;
  text-align: center;
  margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
  .mb32 {
    margin-bottom: 32px
  }
`;


function Section6() {
    const navigate = useNavigate();
    return (
        <SSection
            id={'Section7'}
            center
        >
            <YellowBackground>
                <Typography
                    type="h2"
                >
                    TRY THE GNOSIS VPN PoC!
                </Typography>
                <Subtext>
                    The proof of concept is a work in progress and may be updated at any time. It may therefore be unstable or contain bugs, and should not be used in situations which require full anonymity. Use at your own risk. To manage load and aid with testing,{" "}<a
                    href='/servers#allowlist'
                    style={{
                        width: 'unset',
                        maxWidth: 'unset',
                        height: 'unset',
                        lineHeight: '28px',
                        marginTop: '16px'
                    }}
                    onClick={(event) => {
                        event.preventDefault();
                        navigate("/servers#allowlist");
                    }}
                >
                    an allow list of sites
                </a>{" "}is currently enforced. For testing and debugging, exit nodes currently gather logs. These logs cannot reveal user requests, server responses, IPs or any other identifying information. Logs are deleted after thirty days.
                </Subtext>


            </YellowBackground>
            <YellowBackgroundGradient>

            </YellowBackgroundGradient>
            <VideoBackgroundContainer
                className="VideoBackgroundContainer2"
            >
                <ReactPlayer
                    className="VideoBackground2"
                    url='videos/LtfWiOmg1OxayO1gqV4rD9u9LAA.mp4'
                    playing
                    loop
                    muted
                />
            </VideoBackgroundContainer>
            {/* <WhiteBackground /> */}
            <FlexBox>
                <BrickDownloadWide
                    //     title="LINUX"
                    text={<Content>
                        <Button
                            hopr
                            href='https://github.com/gnosis/gnosis_vpn-client/blob/main/ONBOARDING.md#onboarding'
                            target="_blank"
                            style={{
                                width: 'unset',
                                maxWidth: 'unset',
                                height: 'unset',
                                lineHeight: '28px',
                                marginTop: '16px'
                            }}
                        >
                            GET STARTED
                        </Button>
                    </Content>}
                    image="/images/linux.svg"
                    image2="/images/macOS.svg"
                />
                { /* Uncomment when we will split the wide button into multiple OSs*/}
                {/* <BrickDownload
                    title="LINUX"
                    text={<Content>
                        <a
                            href="https://github.com/gnosis/gnosis_vpn-client/blob/main/ONBOARDING.md#instructions-for-linux"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manual
                        </a>
                        <Button
                            hopr
                            href='https://github.com/gnosis/gnosis_vpn-client/releases'
                            target="_blank"
                            style={{
                                width: 'unset',
                                maxWidth: 'unset',
                                height: 'unset',
                                lineHeight: '28px',
                                marginTop: '16px'
                            }}
                        >
                            DOWNLOAD
                        </Button>
                    </Content>}
                    image="/images/linux.svg"
                />
                <BrickDownload
                    title="MAC OS"
                    text={<Content>
                        <a
                            href="https://github.com/gnosis/gnosis_vpn-client/blob/main/ONBOARDING.md#instructions-for-macos"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            Manual
                        </a>
                        <Button
                            hopr
                            href='https://github.com/gnosis/gnosis_vpn-client/releases'
                            target="_blank"
                            style={{
                                width: 'unset',
                                maxWidth: 'unset',
                                height: 'unset',
                                lineHeight: '28px',
                                marginTop: '16px'
                            }}
                        >
                            DOWNLOAD
                        </Button>
                    </Content>}
                    image="/images/macOS.svg"
                /> */}
            </FlexBox>

        </SSection>
    );
}

export default Section6;
