import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

import Typography from '../Typography/index.jsx'
import Button from '../Button/index.jsx'


const SBrick = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 2px 34px -7px rgb(0 0 0 / 50%);
  min-width: 200px;
  max-width: 420px;
  width: 100%;

  .images2 {
    display: flex;
    flex-direction: row;
    gap: 12px;
    width: 100%;
    max-width: 312px;
    margin: auto;
    margin-top: 40px;
  }

`

const TextContainer = styled.div`
  /* flex: 6; */
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    text-align: left;
  }
  ${props => props.centerText ? 'justify-content: center;' : ''}
  > span {
    width: 100%;
    text-align: center;
    margin-bottom: 20px;
    font-weight: 400;
  }
`

const Image = styled.img`
  height: auto;
  max-width: 45%;
  width: 100%;
  flex: 1;
  flex-shrink: 1;
  margin: 0px auto 32px auto;
`


function Brick(props) {

  return (
    <SBrick
      className={`BrickDownload ${props.reverse ? 'Brick--reverse' : ''} ${props.className}`}
    >
      <TextContainer centerText={props.centerText}>
        <div className="images2">
          <Image
            src={props.image}
          />
          <Image
            src={props.image2}
          />
        </div>
        <Typography
          center
          style={{width: '100%'}}
        >
          {props.text}
        </Typography>
        {
          props.button &&
          <Button
            hopr
            href={props.buttonHref}
            target="_blank"
          >
            {props.button}
          </Button>
        }
        <span>
          * Windows currently not supported.
        </span>
      </TextContainer>

    </SBrick>
  );
}

export default Brick;