import React, { useEffect, useRef } from "react";
import styled from "@emotion/styled";

import Typography from '../Typography/index.jsx'
import Button from '../Button/index.jsx'


const SBrick = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: stretch;
  gap: 16px;
  background-color: white;
  border-radius: 16px;
  padding: 16px;
  box-shadow: 0px 2px 34px -7px rgb(0 0 0 / 50%);
  min-width: 266px;
`

const TextContainer = styled.div`
  flex: 6;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  div {
    text-align: left;
  }
  ${props => props.centerText ? 'justify-content: center;' : ''}

`

const Image = styled.img`
  height: auto;
  max-width: 100%;
  margin: 0px auto 32px auto;
  max-height: 173px;
  height: 100%;
`


function Brick(props) {

  return (
    <SBrick
      className={`BrickVertical ${props.reverse ? 'Brick--reverse' : ''} ${props.className}`}
    >
      <TextContainer centerText={props.centerText}>
        <Image
          src={props.image}
        />
        <Typography
          type="h5"
          center
        >
          {props.title}
        </Typography>
        <Typography
          center
        >
          {props.text}
        </Typography>
        {
          props.button &&
          <Button
            hopr
            href={props.buttonHref}
            target="_blank"
          >
            {props.button}
          </Button>
        }
      </TextContainer>

    </SBrick>
  );
}

export default Brick;