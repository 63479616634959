import React from "react";
import styled from "@emotion/styled";

import Section from '../components/Section/index.jsx'
import Typography from '../components/Typography/index.jsx'
import Button from '../components/Button/index.jsx'

import PoweredByHopr from '../components/PoweredByHOPR/index.jsx'

const SSection = styled(Section)`
    padding-bottom: 40px;
    padding-top: 40px;
    align-items: flex-start;
    /* background-image: url('/images/RPCh-BG.jpg'); */
    /* background-position: top;
    background-size: cover; */
`

const Subtext = styled(Typography)`
    color: #FFF;
    max-width: 640px;
    margin-top: ${(props) => props.marginTop && `${props.marginTop}px`};
    .mb32 {
        margin-bottom: 32px
    }
`;


function Section1B() {
    return (
        <SSection
            id={'Section1B'}
            center
            darkGradient
        >
            <Typography
                type="h6"
                className="mb32"
                style={{ color: 'white' }}
            >
                TRY GNOSIS VPN PoC
            </Typography>
            <Subtext center mb32>
                You can already try the Gnosis VPN PoC to anonymously access select sites and services within the Gnosis ecosystem. Click below and follow the instructions to apply for access. You’ll need to run your own full HOPR node for the PoC. Future versions will greatly reduce these technical requirements.
            </Subtext>
            <Button
                hopr
                white
                target="_blank"
                style={{
                    width: 'unset',
                    maxWidth: 'unset',
                    height: 'unset',
                    lineHeight: '28px'
                }}
                onClick={(event)=>{
                    event.preventDefault()
                    const id = 'Section7';
                    const yOffset = -60;
                    const element = document.getElementById(id);
                    const y = element.getBoundingClientRect().top + window.scrollY + yOffset;
                    window.scrollTo({top: y, behavior: 'smooth'});
                  }}
            >
                TRY PoC
            </Button>
        </SSection>
    );
}

export default Section1B;
